"use strict";
import {on, find, findAllIn, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import axios from "axios";

export function init() {
    onFind('.js-dynamic-tabbing__toggle', function (element) {
        let tab = find(element.getAttribute('href'));

        if(hasClass('active', tab)){
            loadContent({ tab, url: element.getAttribute('data-content-url'), allowScriptTags: true  });
        }

        on('click', function () {
            loadContent({ tab, url: element.getAttribute('data-content-url'), allowScriptTags: true  });
        }, element);
    });
}

export function loadContent({tab, url, data, method, allowScriptTags = false}) {
    let pendingRequest;

    if (method === 'GET') {
        pendingRequest = axios({method: 'GET', url: url, params: data });
    } else {
        pendingRequest = axios({method: 'POST', url: url, data: data });
    }

    asyncAppend({
        target: findAllIn('.js-dynamic-tabbing__content', tab),
        loading: findAllIn('.js-dynamic-tabbing__loading', tab),
        options: {
            allowScriptTags
        }
    }, pendingRequest.then((x) => x.data));

    return pendingRequest;
}
