
import {on, addClass, findIn, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
export function init () {
    onFind('.js-logo-header', function (element) {
        let lastScrollTop = 0;
        const delta = 5; // Change threshold to control sensitivity
        let logo = findIn('.js-logo-header__img', element);

        on('scroll', function() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (Math.abs(lastScrollTop - scrollTop) <= delta) {
                return; // Ignore small scrolls
            }

            if (scrollTop > lastScrollTop) {
                // Scrolling down
                addClass('logo-header__img--visible', logo);
            } else {
                // Scrolling up
                removeClass('logo-header__img--visible', logo);
            }

            lastScrollTop = scrollTop;
        }, window);
    });
}